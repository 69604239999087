body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container{max-width: 576px !important;position: relative;}
select:focus{box-shadow: unset !important;border-color:transparent !important;}

.topwrap img{margin: 0 auto;}
.topmain{top: 0;background: #12354B;z-index: 2;width: 100%;}
.topwrap button:nth-child(1){display: flow-root;text-align: left;margin: 23px 30px;float: left;background: transparent;border: transparent;outline: unset;box-shadow: unset;}
.topwrap button:nth-child(2){display: flow-root;text-align: right;margin: 15px 30px;float: right;background: transparent;box-shadow: unset;border: 0px;}

.footer li.active svg{fill:#02C4FF;}
.footer li.active span{color:#02C4FF;}
.footer li {width: 33%;display: inline-block;text-align: center;}
.footer li a{text-decoration: none;text-align: center;}
.footer li svg{fill:#fff;margin: 0 auto;display: block;}
.footer ul{padding-inline-start: 0px;list-style: none;margin-bottom: 0px;}
.footer{background: #12354B;position: fixed;width: 100%;left: 0;bottom: 0;padding: 15px 0;}
.footer li span{color: #fff;text-decoration: none;display: block;font-size: 12px;font-style: normal;font-weight: 500;line-height: normal;margin-top: 5px;}
.footer li .live svg{fill:#02C4FF;}
.footer li a,.footer li button{text-decoration: none;text-align: center;background: transparent;background-color: transparent;box-shadow: unset;outline: unset;border:0px;padding: 0px;}
.footer li .live span{color:#02C4FF;}
/*Start Loader*/
.loadingClass{
	 margin: 0;
    background: rgba(0,0,0,0.6);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100vh;
}
.loadingClass img{
    width: 60px; 
	 margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
/*End Loader*/


