@each $prop, $value in $theme-colors {
  .badge.bg-#{$prop} {
    background: $value;
  }
}
.badge {
  text-transform: uppercase;
}
.badge:hover {
  @extend .shadow;
}
